// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$cognitivedemo-primary: mat-palette($mat-indigo);
$cognitivedemo-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$cognitivedemo-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$cognitivedemo-theme: mat-light-theme((color: (primary: $cognitivedemo-primary,
      accent: $cognitivedemo-accent,
      warn: $cognitivedemo-warn,
    )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($cognitivedemo-theme);

/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

* {
  font-family: 'Open Sans', sans-serif;
  padding: 0px;
  margin: 0px;
}

// ::-webkit-scrollbar {
//   display: none;
// }

@font-face {
  font-family: "Clash Display Medium";
  src: url("assets/fonts/clash-display/ClashDisplayMedium.otf")
  format("truetype");
}
@font-face {
  font-family: "Rethink Sans Regular";
  src: url("assets/fonts/rethink-sans/RethinkSans-Regular-BF657912272f089.ttf")
  format("truetype");
}
@font-face {
  font-family: "Rethink Sans SemiBold";
  src: url("assets/fonts/rethink-sans/RethinkSans-SemiBold-BF657912271e146.ttf")
  format("truetype");
}
@font-face {
  font-family: "Rethink Sans ExtraBold";
  src: url("assets/fonts/rethink-sans/RethinkSans-ExtraBold-BF657912264cc18.ttf")
  format("truetype");
}

$color-primary-100: #023047;
$color-primary-200: #19a167;
// Info colors
$color-info-100: var(--Colors-Base-White, #f8f9fa);
$color-info-200: var(--Colors-Highlight-Highlight-Light-02, #bbbfc3);
// Warning colors
$color-warning-100: var(--Colors-Highlight-Highlight-Bright, #ba0001);

html,
body {
  // height: 100%;
  background-color: #292f40;
}

body {
  margin: 0;


}

// Hide by default eye icon
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

input[type="password"]::-webkit-input-clear-button,
input[type="password"]::-webkit-input-clear-button,
input[type="password"]::-webkit-input-password-toggle-button {
  display: none;
}
// Remove the focus outline
input {
  border: none;
  outline: none;
}
// Remove border and outline from buttons
button {
  border: none; // Remove any border
  outline: none; // Remove the focus outline
  background: none; // Remove default background if necessary
  cursor: pointer;
}
// Additional styles for button focus states
button:focus,
button:active {
  outline: none; // Remove outline when focused or active
  border: none; // Remove any border
}
.main_view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  background-color: $color-primary-100;
  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 24px 40px 24px 44px;
    .logo {
      width: 79.308px;
      height: var(--19, 76px);
      flex-shrink: 0;
    }
    .support {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      color: $color-info-100;
      font-family: "Rethink Sans Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .center_container {
    display: flex;
    padding: 0;
    margin: 0;
    justify-content: center;
    align-items: center;
    width:100%;
    height: 100%;
    .left-part {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      margin-bottom: 50px;


    }
    .right-part {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left:30px;
      height: auto;
      width: auto;
      .heading {
        font-family: "Clash Display Medium";
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: $color-info-100;
      }

      .form_container {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
      }

      .input-container {
        position: relative;
        width: fit-content;
      }

      .input-container {
        position: relative;
        width: fit-content;

        input {
          font-family: "Rethink Sans Regular";
          display: flex;
          width: var(--100, 370px);
          height: var(--10, 40px);
          padding: 10px 10px 10px var(--5, 20px);
          align-items: center;
          gap: 10px;
          border: none;
          border-radius: var(--6, 24px);
          background: $color-info-200;
        }

        .input-icon {
          position: absolute;
          right: 10px; /* Adjust as needed */
          top: 50%;
          transform: translateY(-50%);
          pointer-events: none; /* Ensure the icon doesn't block input focus */
        }

        .resend-icon {
          position: absolute;
          right: -8%;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }

        .toggle-password-visibility {
          position: absolute;
          right: 30px; /* Adjust as needed */
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
        }
      }

      .sign-in-btn:disabled {
        display: flex;
        width: max-content;
        height: var(--11, 44px);
        padding: 10px 80px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 25px;
        background: linear-gradient(89deg, #495057 0%, #bbbfc3 152.3%);
        color: var(--Colors-Text-Text-Secondary, #bbbfc3);
        font-family: "Rethink Sans ExtraBold";
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }

      .sign-in-btn {
        display: flex;
        width: max-content;
        height: var(--11, 44px);
        padding: 10px 80px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border: none;
        border-radius: 25px;
        background: linear-gradient(88deg, #19a167 3.47%, #017268 93.07%);
        box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25);
        color: $color-info-100;
        font-family: "Rethink Sans ExtraBold";
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }

      .send-mail-btn {
        display: flex;
        width: 120px;
        height: var(--11, 44px);
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border: none;
        border-radius: 25px;
        background: linear-gradient(88deg, #19a167 3.47%, #017268 93.07%);
        box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25);
        color: $color-info-100;
        font-family: "Rethink Sans ExtraBold";
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }

      .back-sign-in-btn {
        display: flex;
        width: 148px;
        height: var(--11, 44px);
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 25px;
        border: 1px solid #19a167;
        background: linear-gradient(263deg, #017268 -68.67%, #19a167 145.99%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-family: "Rethink Sans ExtraBold";
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }

      .forgot_pass {
        font-family: "Rethink Sans Regular";
        color: $color-primary-200;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-left: 20px;
        margin-bottom: 16px;
        padding-top: 8px;
        text-align: left;
        cursor: pointer;
      }

      .error-message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--0, 0px);
        align-self: stretch;
        margin-bottom: 16px;

        .error-message-heading {
          display: flex;
          align-items: center;
          color: $color-info-100;
          font-family: "Rethink Sans ExtraBold";
          font-size: 16px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
        }

        .error-message-content {
          color: $color-info-100;
          text-align: center;
          font-family: "Rethink Sans Regular";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .success-message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--0, 0px);
        align-self: stretch;
        margin-bottom: 16px;

        .success-message-heading {
          display: flex;
          align-items: center;
          color: $color-primary-200;
          font-family: "Rethink Sans ExtraBold";
          font-size: 16px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
        }

        .success-message-content {
          color: $color-primary-200;
          text-align: center;
          font-family: "Rethink Sans Regular";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .info-message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 20px;
        gap: var(--0, 0px);
        align-self: stretch;
        margin-bottom: 16px;

        .info-message-heading {
          display: flex;
          align-items: center;
          color: $color-primary-200;
          font-family: "Rethink Sans ExtraBold";
          font-size: 16px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
        }

        .info-message-content {
          color: var(--Colors-Text-Text-Teritiary, #495057);
          font-family: "Rethink Sans Regular";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .valid {
          color: green;
        }
      }

      .resend-otp {
        color: var(--Colors-Text-Text-Teritiary, #495057);
        text-align: right;
        font-family: "Rethink Sans Regular";
        font-size: 16px;
        font-style: normal;
        margin-top: 8px;
        font-weight: 400;
        line-height: normal;
      }

      .redirect {
        margin: 0 auto;
        padding: 20px;
        text-align: center;
        margin: 0 auto;
        padding: 20px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .success {
        font-family: "Rethink Sans Regular";
        font-size: 16px;
        color: $color-primary-200;
      }
    }
  }
  .footer {
    color: $color-info-100;
    font-family: "Rethink Sans Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 20px;
  }
}
// Header
.header {
  background-color: $color-primary-100;
  padding: 9px 60px;
  .lns_logo {
    width: 42px;
    height: 40px;
  }
  .profile_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    margin-right: 15px;
    .profile_frame {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 14px;
    }
    .user_profile {
      display: flex;
      width: var(--10, 40px);
      height: var(--10, 40px);
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
    .user_name {
      color: $color-info-100;
      text-align: right;
      font-family: "Rethink Sans Regular";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .user_role {
      color: $color-info-100;
      text-align: right;
      font-family: "Rethink Sans Regular";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .expand_icon {
      color: $color-info-100;
    }
  }
}


::-webkit-scrollbar {
  display: block ;
}

/* width */
// ::-webkit-scrollbar {
//   width: 10px;
// }

/* Track */
// ::-webkit-scrollbar-track {
//   background: #f1f1f1;
// }

/* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #888;
// }

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }
.title1 {
  color: #0091EA;
}

.title2 {
  color: #00C853;
}





/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}

.purple-button {
  background: #673ab7;
  color: #fff;
}

.configuration-title {
  font-size: 1.7rem;
  height: 100%;
  vertical-align: middle;
  color: white;
}

.normal-modal-class .mat-dialog-container {
  width: 800px;
  background: #252A3A;
  resize: none !important;
  border: white 1px solid !important;
}

.msg-modal-class .mat-dialog-container {
  width: auto;
  // height: 200px;
  background: #252A3A;
  resize: none !important;
  position: relative;
  margin-top: 32%;
  margin-bottom: 56%;
  // border: white 1px solid !important;
}

.main-modal-class .mat-dialog-container {
  width: 800px;
  background: #252A3A;
  resize: none !important;
  border: white 1px solid !important;
}

.loading-modal-class .mat-dialog-container {
  width: 100%;
  background: transparent !important;
  resize: none !important;
  box-shadow: none !important;
}

.backdropBackground {
  background-color: rgba(255, 255, 255, .4);
}

.my-page-body {
  $font_family_montserrat: Montserrat;
  $font_weight_light: 300;
  $font_weight_regular: 400;
  $font_weight_medium_1: 500;
  $font_weight_medium_2: 600;
  $font_weight_bold: bold;
  $header_height: 12vh;
  $border_radius_1: 13.995px;
  $border_width: 0.933px solid;
  $brand_logo_width: 232px;
  $brand_logo_height: 74px;
  $user_profile_width: 38px;
  $user_profile_height: 38px;
  $body_margin: 0;
  // info colors
  $color-info-100: #ffffff;
  $color-info-200: #fafbfc;
  $color-info-300: #fbfffe;
  $color-info-400: #f6f0ed;
  $color-info-500: #f4f4ed;
  $color-info-600: #2d2d34;
  $color-info-700: #19231a;
  $color-info-800: #141414;
  $color-info-900: #01110a;
  $color-info-1000: #000000;
  // basic Colors
  $color-basic-100: #44b2cb;
  $color-basic-200: #73c5d8;
  $color-basic-300: #1b4c89;
  // Primary colors
  $color-primary-100: #00b050;
  $color-primary-200: #27ae60;
  $color-primary-300: #c9e2c3;
  $color-primary-400: #183c7c;
  // secondary colors
  $color-secondary-100: #4f4f4f;
  $color-secondary-200: #828282;
  $color-secondary-300: #e0e0e0;
  $color-secondary-400: #f4f4f4;
  $color-secondary-500: #ebebeb;
  $color-secondary-600: #e5e0e0;
  $color-secondary-700: #737373;
  $color-secondary-800: #cdd5de;
  $color-secondary-900: #333;
  $color-secondary-1000: #d2d2d2;
  $color-secondary-1100: #323740;
  $color-secondary-1200: #9c9ea1;
  $color-secondary-1300: #5d5858;
  // danger colors
  $color-danger-100: #ff0000;
  $color-danger-200: #9f1e23;

  body {
    margin: $body_margin;
    font-family: $font_family_montserrat;
    background-color: #bdbdbd;
  }

  html,
  body {
    height: 100%;
  }

  // Mixins
  @mixin card_styles($background, $border_color) {
    border-radius: $border_radius_1;
    border: $border_width $border_color;
    background: $background;
  }

  .col,
  .col-12,
  .col-md-6,
  .col-lg-3 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }

  .col,
  .col-12,
  .col-md-12,
  .col-lg-6 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }

  .col,
  .col-12,
  .col-md-12,
  .col-lg-12 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }

  // Body scss


  // table scss
  .table {
    width: 100%;
    margin: 0px;
  }

  .table,
  .table th,
  .table td {
    border: none;
  }

  .table th {
    padding: 6px;
    color: $color-info-100;
    font-size: 14px;
    font-style: normal;
    font-family: $font_family_montserrat;
    font-weight: $font_weight_bold;
    // text-align: center;
    background-color: #00b050;
    /* Fixed header background color */
    position: sticky;
    top: 0;
  }

  .table td {
    padding: 6px;
    color: $color-info-1000;
    font-size: 12px;
    font-family: $font_family_montserrat;
    font-weight: $font_weight_regular;
    // text-align: center;
  }

  .table1 {
    width: 100%;
    margin: 0px;
  }

  .table1,
  .table1 th,
  .table1 td {
    border: none;
  }

  .table1 th {
    padding: 2px 2px;
    color: $color-info-100;
    font-size: 10px;
    font-style: normal;
    font-family: $font_family_montserrat;
    font-weight: $font_weight_bold;
    // text-align: center;
    background-color: #3467C1;
    /* Fixed header background color */
    position: sticky;
    top: 0;
  }

  .table1 td {
    padding: 6px 2px;
    color: $color-info-1000;
    font-size: 10px;
    font-family: $font_family_montserrat;
    font-weight: $font_weight_regular;
    // text-align: center;
  }
  .table1 tr:nth-child(odd) {
    background-color: rgba(116, 202, 226, 42%);
  }

  .sku {
    cursor: pointer;
  }

  .sku:active {
    background-color: #183C7C;
    color: $color-info-100;
  }

  .table tr:nth-child(odd) {
    background-color: #c9e2c3;
  }

  .basic {
    border-collapse: collapse;
    width: 100%;
  }

  .basic,
  .basic th,
  .basic td {
    border: 1px solid #ccc;
  }

  .basic th {
    padding: 2px 4px;
    color: $color-secondary-900;
    font-size: 13px;
    font-style: normal;
    font-weight: $font_weight_medium_2;
  }

  .basic td {
    padding: 2px 4px;
    color: $color-info-1000;
    font-size: 12px;
    font-weight: $font_weight_regular;
  }

  // .basic th {
  //   background-color: #f2f2f2;
  // }
  .basic tr {
    margin: 2px 0 2px 0;
  }

  // .basic tr:nth-child(even) {
  //   background-color: #f7f7f7;
  // }
  .basic td.numbers {
    text-align: right;
  }

  // .hover_table tbody tr:hover {
  //   background-color: #c9e2c3;
  //   color: $color-info-1000;
  // }
  // Button outline scss
  button:focus {
    outline: none !important;
  }

  // h1 scss is for whole application
  h1 {
    // color: $color-secondary-1100;

    font-size: 25px;
    font-weight: $font_weight_light;
    margin-left: 20px;
    margin-bottom: 0px;
    padding-top: 5px;
    // margin-top: 5px;
    // text-transform: uppercase;
  }

  // app title scss
  .title {
    text-transform: uppercase;
    font-size: 30px;
    display: flex;
    justify-content: center;
    // color: $color-secondary-900;
    align-items: center;
    font-weight: $font_weight_regular;

  }

  .app_title {
    font-size: 25px;
    font-weight: $font_weight_light;
    margin-left: 15px;
  }

  // scrollbar scss
  // ::-webkit-scrollbar {
  //   width: 0;
  // }
  // ::-webkit-scrollbar-thumb {
  //   background: transparent;
  //   box-shadow: none;
  // }
  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background: $color-basic-200;
    box-shadow: none;
    border-radius: 10px;
  }

  // divder scss
  .divder {
    height: 41px;
    border-left: 0.25px solid $color-secondary-1300;
    // margin: 0 15px 0 10px;
  }

  // Search box-css

  .input-container {
    position: relative;
    display: inline-block;
  }

  .search-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }

  .input-container input {
    padding-left: 35px;
    /* Adjust this value based on the icon size and padding */
    width: 100%;
  }

  .custom-input {
    padding-left: 35px;
    /* Adjust this value based on the icon size and padding */
    width: 100%;
    border: none;
    border-bottom: 2px solid #ccc;
    outline: none;
    /* Remove default focus outline */
  }

  .custom-input:focus {
    border-bottom-color: #000;
    /* Change color when focused */
  }




  // login scss
  .loginHandler {
    font-size: 12px;
    font-family: $font_family_montserrat;
    color: $color-danger-100;
  }

  // Header scss
  .header {
    display: flex;
    height: $header_height;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    // background: $color-info-100;
    // border-bottom: $border_width $color-secondary-600;
    padding: 10px 0px;

    .menu_container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .logo_container {
      // height: 63px;
      // width: 14.34vw;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      padding: 10px;
    }

    .profile_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
      margin-right: 15px;
    }

    .profile_frame {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 14px;
    }

    .gops_logo {
      width: 135px;
      // height: 34px;
      flex-shrink: 0;
    }

    .lns_logo {
      width: 56px;
      height: 54px;
      flex-shrink: 0;
    }

    .user_profile {
      width: $user_profile_width;
      height: $user_profile_height;
      flex-shrink: 0;
    }

    .user_name {
      text-align: right;

      font-size: 14px;
      font-weight: $font_weight_medium_1;
      text-transform: capitalize;
    }

    .user_role {
      text-align: right;

      font-size: 10px;
      font-weight: $font_weight_regular;
      text-transform: capitalize;
    }

    .lastupdate {

      font-weight: $font_weight_regular;
      font-size: 13px;
      text-align: right;
    }

    .powerd_by {
      color: $color-info-1000;

      font-size: 13px;
      font-style: normal;
      font-weight: $font_weight_regular;
      line-height: normal;
      text-transform: capitalize;
    }
  }

  .apply_button {
    background-color: $color-primary-400;
    color: $color-info-100;
    padding: 8px;

    font-size: 14px;
    font-weight: $font_weight_medium_1;
    letter-spacing: 0.5px;
    border-radius: 5px;
    cursor: pointer;
    outline: auto;
  }

  .example-form-field {
    margin-left: 20px;
  }

  // Footer scss
  .footer-element {
    // background: $color-info-100;
    // border-top: $border_width;
    // color: $color-secondary-1100;
    padding: 10px;
    // margin-top: 10px;
    text-align: center;
    z-index: 1;
  }

  // Sidenav scss
  .sidenav_container {
    width: 100%;
    height: 100%;
    // background-color: $color-info-100;
    // color: $color-info-1000;
    display: flex;
    flex-direction: column;
    transition: width 0.3s ease;
    position: relative;
    z-index: 999;

    // box-shadow: -1px 0px 0px 0px #dcd7d7 inset;
    .menu_item {
      // color: $color-info-1000;
      text-align: justify;
      font-size: 14px;
      font-weight: $font_weight_regular;
      line-height: normal;
      align-self: stretch;
      padding: 18px 0px 18px 16px;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      display: inline-flex;
      transition: background-color 0.3s, color 0.3s;
      cursor: pointer;

      &.active {
        // background-color: $color-primary-100;
        // color: $color-info-100;
        font-weight: $font_weight_medium_2;

        .Icon {
          // fill: $color-info-100;
          height: 18px;
          width: 18px;
        }

        .Icon1 {
          // fill: $color-info-100;
          // stroke: $color-info-100;
          height: 18px;
          width: 18px;
        }
      }

      // &:hover {
      //   background-color: $color-primary-300;
      //   // color: $color-info-1000;
      //   // font-weight: $font_weight_regular;
      //   // .Icon {
      //   //     fill: $color-info-1000
      //   //   }
      // }
      .Icon {
        // fill: $color-info-1000;
        width: 18px;
        height: 18px;
      }

      .Icon1 {
        // fill: $color-info-1000;
        // stroke: $color-info-1000;
        height: 18px;
        width: 18px;
      }

      P {
        margin: 0;
        text-align: left;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .navbar_container {
      gap: 5px;
      flex-wrap: wrap;
      justify-content: unset !important;
    }
  }

  // Navbar
  .navbar_container {
    // height: 9vh;
    // gap: 15px;
    // flex-wrap: wrap;
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    // justify-content: space-between;
    position: relative;

    .menu_item {
      // color: $color-info-1000;
      height: 43px;
      border-radius: 4px;
      text-align: justify;
      font-size: 12px;
      font-weight: $font_weight_regular;

      line-height: normal;
      align-self: stretch;
      padding: 16px;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      display: inline-flex;
      transition: background-color 0.3s, color 0.3s;
      cursor: pointer;

      &.active {
        // background-color: $color-primary-100;
        // color: $color-info-100;
        font-weight: $font_weight_medium_2;

        .Icon {
          // fill: $color-info-100;
          height: 18px;
          width: 18px;
        }

        .Icon1 {
          // fill: $color-info-100;
          // stroke: $color-info-100;
          height: 18px;
          width: 18px;
        }
      }

      // &:not(.active) {

      //   cursor: not-allowed !important;
      //   pointer-events: none;
      // }
      // &:hover {
      //   background-color: $color-primary-300;
      //   // color: $color-info-1000;
      //   // font-weight: $font_weight_regular;
      //   // .Icon {
      //   //     fill: $color-info-1000
      //   //   }
      // }
      .Icon {
        // fill: $color-info-1000;
        width: 18px;
        height: 18px;
      }

      .Icon1 {
        // fill: $color-info-1000;
        // stroke: $color-info-1000;
        height: 18px;
        width: 18px;
      }

      P {
        margin: 0;
        text-align: left;
      }
    }

    .menu_items {
      // color: $color-info-1000;
      height: 43px;
      border-radius: 4px;
      text-align: justify;
      font-size: 12px;
      font-weight: $font_weight_regular;

      line-height: normal;
      align-self: stretch;
      padding: 16px;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      display: inline-flex;
      transition: background-color 0.3s, color 0.3s;
      cursor: pointer;

      &.active {
        // background-color: $color-primary-100;
        // color: $color-info-100;
        font-weight: $font_weight_medium_2;

        .Icon {
          // fill: $color-info-100;
          height: 18px;
          width: 18px;
        }

        .Icon1 {
          // fill: $color-info-100;
          // stroke: $color-info-100;
          height: 18px;
          width: 18px;
        }
      }

      // &:not(.active) {

      //   cursor: not-allowed !important;
      //   pointer-events: none;
      // }
      // &:hover {
      //   background-color: $color-primary-300;
      //   // color: $color-info-1000;
      //   // font-weight: $font_weight_regular;
      //   // .Icon {
      //   //     fill: $color-info-1000
      //   //   }
      // }
      .Icon {
        // fill: $color-info-1000;
        width: 18px;
        height: 18px;
      }

      .Icon1 {
        // fill: $color-info-1000;
        // stroke: $color-info-1000;
        height: 18px;
        width: 18px;
      }

      P {
        margin: 0;
        text-align: left;
      }
    }
  }


  // Welcome Page CSS
  .content_bg {
    .content_Area {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // position: absolute;
      // left: 10%;
      align-items: center;
    }

    .View2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      // background-image: url(./assets/Images/Welco.png);
      background-size: cover;
      background-repeat: no-repeat;
      height: 88vh;
      font-family: Roboto !important;
    }

    .transcard {
      width: 600px;
      padding: 10px;
      background: linear-gradient(to right, #fff, #74cae2);
      // position: absolute;
      // top: 115%;
      color: #323740;
      border-radius: 10px;
      box-shadow: 5px 5px;
      font-size: 14px;
      overflow: auto;
      max-height: 300px;
    }

    .footertext {
      font-size: 11px;
      text-align: center;
      // left: 25%;
      // position: absolute;
      top: 185%;
      color: #fff;
    }

    button {
      background-color: #00308f;
      color: white;
      border: none;
      padding: 4px 25px;
      border-radius: 5px;
      cursor: pointer;
      margin-top: 10px;
    }
  }

  // Sub Header scss
  .sub_header {
    // background-color: $color-info-100;
    // color: $color-info-100;
    padding: 10px;
    // border-bottom: 1px solid $color-secondary-600;
    z-index: 1;
    // height: 50px;
    height: max-content;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-left: 6px;
    // margin-right: 8px;
    border-radius: 10px;

    // last update scss
    p {

      font-size: 14px;
      font-weight: 400;
      line-height: 100%;
      font-variant: all-small-caps;
      letter-spacing: -0.75px;
      padding-right: 6px;
      margin: 0px;
      width: 30%;
    }

    .lastupdate {
      font-family: $font_family_montserrat;
    }

    .left_container {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 8px;

      // margin-left: 20px;
      .date_filter_container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .date_filter_button {
          max-width: max-content;
          height: 28px;
          border: unset;
          border: 1px solid $color-secondary-800;
          border-radius: 4px;
          background: $color-info-100;
          outline: unset;
          color: $color-secondary-700;
          padding: 16px;
          cursor: pointer;
          font-size: 13px;
          font-weight: 300;
          display: flex;
          align-items: center;

        }

        .cal_filter_button {
          max-width: max-content;
          border: unset;
          border: 1px solid $color-secondary-800;
          border-radius: 4px;
          background: $color-info-100;
          outline: unset;
          color: $color-secondary-700;
          margin-left: 8px;
          height: 33.6px;
          width: 40px;
          cursor: pointer;
          font-size: 15px;
          font-weight: 300;
          display: flex;
          align-items: center;
        }

        .date_filter_button.selected {
          font-weight: $font_weight_medium_1;
        }

        .cal_filter_button.selected {
          font-weight: $font_weight_medium_1;
        }
      }

      p {

        font-size: 14px;
        font-weight: 400;
        line-height: 100%;
        font-variant: all-small-caps;
        letter-spacing: -0.75px;
        padding-right: 6px;
        margin: 0px;
      }
    }
  }

  // Kpi Container
  .kpi_container {

    // margin: 0px 13px 0 13px;
    // padding-bottom: 15px;
    .card_content {
      // color: $color-info-1000;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      // height: 100%;
    }

    .sub_card_content {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .card_head_container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .card_heading {
      font-size: 13px;
      font-weight: $font_weight_regular;
      margin-left: 10px;
    }

    .card_sub_heading {
      font-size: 13px;
      font-weight: $font_weight_regular;
    }

    .value_container {
      display: flex;
      flex-direction: column;
    }

    .kpi_value {
      font-size: 20px;
      font-weight: $font_weight_light;
    }

    .card2 {
      display: flex;
      flex-direction: row;
      padding: 10px;
      padding-bottom: 0;
      margin: 10px 0px;
      border-radius: 10px;
      height: 82px;
    }

    .card_icon {
      height: 22px;
      width: 20px;
    }

    .card_icon1 {
      fill: $color-basic-200;
      height: 22px;
      width: 20px;
    }
  }

  // main container
  .main_container {
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: #ffffff;

    .demand_header {
      display: flex;
      justify-content: space-between;
      padding: 5px 0px;
      align-items: center;
      border-bottom: 1px solid #42474F;

      .head_name {

        font-size: 18px;
        color: #333333;
      }
    }

    .export_button {
      background-color: $color-primary-200;
      border-radius: 5px;
      gap: 5px;
      color: #ffffff;
      font-family: $font_family_montserrat;
      font-size: 15px;
      padding: 10px;
      text-align: center;
      width: max-content;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .table_container {
      margin: 5px;
      min-height: 500px;
      max-height: 500px;
      overflow-y: auto;
    }

    .create_plan {
      background-color: rgba(116, 202, 226, 42%);
      height: 200px;
      display: flex;
      margin: 5px;
      justify-content: center;
      align-items: center;

      .create_plan_placeholder {
        text-align: center;

        font-size: 18px;
        font-weight: $font_weight_medium_1;
        color: rgb(115, 115, 115, 70%);
      }

      .create_plan_placeholder1 {
        font-size: 19px;
        color: #000000;
      }
    }

    .chart_card {
      border-radius: 10px;
      margin: 10px;
      padding: 10px;

      .heading {
        font-size: 15px;
        font-weight: $font_weight_medium_2;
        margin-bottom: 10px;
      }
    }

    // .card3 {
    //   height: 190px;
    //   width: 100%;
    //   border-radius: 10px;
    //   .heading {
    //     font-size: 15px;
    //     font-weight: $font_weight_medium_2;
    //     margin-bottom: 10px;
    // }
    // }
  }

  // Card scss
  .card_container {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    margin: 0px 10px 0 10px;

    h1 {
      // color: $color-secondary-900;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 20px;
      font-style: normal;
      font-weight: $font_weight_regular;
      font-family: $font_family_montserrat;
      margin: 0px;
      padding: 0px;
    }

    h6 {
      color: $color-primary-200;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 13px;
      font-weight: 500;
      margin: 0;
      padding-left: 5px;
    }

    p {
      margin: 3px 0px;
      color: $color-info-1000;
      font-size: 12px;
      font-weight: $font_weight_regular;
    }

    // .view_button {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   background-color: $color-info-100;
    //   color: $color-danger-200;
    //   border-radius: 5px;
    //   border: $border_width $color-secondary-1000;
    //   width: 87px;
    //   height: 26px;
    //   font-family: $font_family_montserrat;
    //   font-size: 12px;
    //   cursor: pointer;
    // }
    .card_heading {
      color: $color-secondary-900;
      font-size: 16px;
      font-style: normal;
      font-weight: $font_weight_medium_2;
    }

    .card_heading1 {
      color: $color-info-800;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      padding: 5px 10px;
      // border-bottom: 0.5px solid $color-secondary-1200;
      border-radius: $border_radius_1 $border_radius_1 0 0;
    }

    .card_sub_heading {
      color: $color-secondary-900;
      font-size: 14px;
      font-style: normal;
      font-weight: $font_weight_medium_2;
    }

    .simulator_content {
      color: $color-secondary-900;
      font-size: 14px;
      font-style: normal;
      font-weight: $font_weight_regular;
    }

    .sub_heading {
      color: $color-secondary-900;
      font-size: 13px;
      font-style: normal;
      font-weight: $font_weight_medium_2;
      margin: 10px 0px;
    }

    .sub_heading1 {
      color: $color-secondary-900;
      font-size: 13px;
      font-style: normal;
      font-weight: $font_weight_medium_2;
      margin: 5px 0px;
    }

    .card_content {
      color: $color-info-1000;

      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      // padding: 10px;
    }

    .card_left_content {
      display: flex;
      flex-direction: column;
    }

    .card_right_content {
      display: flex;
      flex-direction: column;
    }

    .flyash_card_table_content {
      margin: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      gap: 5px;
      overflow-y: scroll;
    }

    .quality_table {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 5px;
      overflow-y: scroll;
    }

    .quality_table1 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 100%;
      gap: 5px;
      overflow-y: scroll;
    }

    .dashcard_content1 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 15px;
    }

    .simulator_dropdown {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      // padding: 10px 5px 10px 5px;
      // border-radius: $border_radius_1;
    }

    .simulator_parameter {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 10px 5px 10px 5px;
      border-radius: $border_radius_1;
    }

    .card1 {
      @include card_styles($color-secondary-400, $color-secondary-300);
      display: flex;
      flex-direction: row;
      padding: 11px 18px;
      // width: calc(23% - 10px - 15px);
      // height: 100px;
      margin: 10px 0px;
    }

    .card2 {
      @include card_styles($color-primary-400, $color-secondary-300);
      display: flex;
      flex-direction: row;
      padding: 11px 18px;
      // width: calc(23% - 10px - 15px);
      // height: 100px;
      margin: 10px 0px;
    }

    .card3 {
      @include card_styles($color-secondary-500, $color-secondary-300);
      height: 190px;
      width: 100%;
      // margin: 10px 0px;
    }

    .card4 {
      @include card_styles($color-info-100, $color-secondary-1200);
      // width: calc(50% - 10px);
      // height: 388px;
      margin: 10px 0px;
    }

    .card5 {
      @include card_styles($color-secondary-400, $color-secondary-300);
      display: flex;
      flex-direction: row;
      padding: 11px 18px;
      // width: calc(48% - 10px - 15px);
      // height: 95px;
      margin: 10px 0px;
    }

    .card6 {
      @include card_styles($color-secondary-400, $color-secondary-300);
      display: flex;
      flex-direction: row;
      padding: 11px 18px;
      // width: calc(48% - 10px - 15px);
      height: 190px;
      margin: 10px 0px;
    }

    .card7 {
      @include card_styles($color-secondary-400, $color-secondary-300);
      display: flex;
      flex-direction: row;
      padding: 11px 18px;
      // width: calc(100% - 10px - 15px);
      height: 425px;
      margin: 10px 0px;
    }

    .card8 {
      @include card_styles($color-secondary-400, $color-secondary-300);
      display: flex;
      flex-direction: row;
      padding: 11px 18px;
      // width: calc(48% - 10px - 10px);
      // height: 250px;
      margin: 10px 0px;
    }

    .left_card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 10px 0px;
      gap: 10px;
    }

    .card_icon {
      fill: $color-basic-100;
      height: 50px;
      width: 50px;
    }

    .card_icon1 {
      fill: $color-basic-200;
      height: 50px;
      width: 50px;
    }
  }

  // popup scss
  .popup_container {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    padding: 25px 15px 10px 15px;
    gap: 20px;
    height: 100%;

    .boiler_image {
      width: 1250px;
      height: 500px;
    }

    .popup_heading_container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .popup_heading {
      color: $color-secondary-900;
      font-family: $font_family_montserrat;
      font-size: 40px;
      font-weight: $font_weight_medium_1;
      display: flex;
      justify-content: center;
    }

    .popup_content {
      font-size: 20px;
      font-family: $font_family_montserrat;
      font-weight: $font_weight_medium_1;
      color: rgb(0, 0, 0, 75%);
      // width: 60%;
      margin-bottom: 15px;
      text-align: center;
    }

    .create_btn {
      background-color: $color-primary-100;
      border-radius: 6px;
      color: #ffffff;
      border: 1px solid #666666;
      padding: 10px;
      font-size: 17px;
      font-weight: 500;
      cursor: pointer;
      width: 180px;
    }

    .create_btn:active {
      background-color: #1e3a63;
    }

    .delete_btn {
      background-color: rgb(255, 69, 69);;
      border-radius: 6px;
      color: #ffffff;
      border: 1px solid #666666;
      padding: 10px;
      font-size: 17px;
      font-weight: 500;
      cursor: pointer;
      width: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .delete_btn:active {
      background-color: #D8746E;
    }

    .keep_btn {
      background-color: #ffffff;
      border-radius: 6px;
      color: #000000;
      border: 1px solid #A6A2A2;
      padding: 10px;
      font-size: 17px;
      font-weight: 500;
      cursor: pointer;
      width: 180px;
    }

    .close_icon {
      fill: $color-info-1000;
    }
  }
  .create_btn {
    background-color: $color-primary-100 !important;
    border-radius: 6px;
    color: #ffffff;
    border: 1px solid #666666;
    padding: 10px;
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
    width: 180px;
  }

  .create_btn:active {
    background-color: $color-primary-300 !important;
  }

  .keep_btn {
    background-color: #ffffff !important;
    border-radius: 6px;
    color: #000000;
    border: 1px solid #A6A2A2;
    padding: 10px;
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
    width: 180px;
  }
  // Recom heading scss
  .Recom_heading {
    // color: $color-info-1000;

    font-size: 20px;
    font-style: normal;
    font-weight: $font_weight_medium_1;
    margin: 0px 0 0px 20px;
  }

  .image_content {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
  }

  // Dropdown scss
  #selectParams {
    outline: #a9a9a9;
    // background-color: #d9d9d9;
    padding: 0 10px;
    height: 30px;
    width: 100%;
    font-size: 12px;
    line-height: 22px;
    border-radius: 6px;
    color: #0f0f0f;
    border: 1px solid $color-secondary-800 !important;
    font-family: $font_family_montserrat;
  }

  // zoom icon scss
  .zoom_icon {
    height: 20px;
    width: 20px;
  }

  // Dashboard scss
  .dash_table_heading {
    text-align: left;
  }

  .dash_table_heading1 {
    text-align: left;
  }

  // Media Query scss
  @media (max-width: 1024px) {
    .header {
      display: flex;
      height: $header_height;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      background: $color-info-100;
      border-bottom: $border_width $color-secondary-600;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .header {
      height: 94px;

      .lns_logo {
        height: 54px;
        width: 64px;
      }
    }

    .col-6 {
      padding: 0;
    }

    .title {
      font-size: 20px !important;
    }

    .divder {
      height: 54px;
    }

    .burneropt_table {
      height: 154px !important;
    }

    .sub_header {
      flex-wrap: wrap;
      width: auto;
      overflow-x: scroll;
      padding: 10px 10px 0px 10px;
      gap: 10px;
    }

    .left_container {
      margin: 0px !important;
    }

    .dashcard_content1 {
      gap: 10px !important;
    }
  }

  @media (min-width: 300px) and (max-width: 423px) {
    .header {
      .lns_logo {
        height: 54px;
        width: 64px;
      }
    }

    .burneropt_table {
      height: 136px !important;
      flex-direction: column !important;
    }

    .burneropt_table1 {
      overflow-y: scroll !important;
      flex-direction: column !important;
    }

    .sub_header {
      flex-wrap: wrap;
      width: auto;
      overflow-x: scroll;
      padding: 10px 10px 0px 10px;
      gap: 10px;
    }

    .left_container {
      margin: 0px !important;
    }

    .popup_container {
      #chartPopup {
        width: 1200px !important;
      }
    }

    .flyash_card_table_content {
      flex-direction: column !important;
    }

    .quality_table {
      flex-direction: column !important;
    }
  }

  @media (min-width: 425px) and (max-width: 767px) {
    .header {
      .lns_logo {
        height: 54px;
        width: 64px;
      }
    }

    .title {
      font-size: 20px;
    }

    .burneropt_table {
      height: 160px !important;
      flex-direction: column !important;
    }

    .burneropt_table1 {
      overflow-y: scroll !important;
      flex-direction: column !important;
    }

    .sub_header {
      flex-wrap: wrap;
      width: auto;
      overflow-x: scroll;
      padding: 10px 10px 0px 10px;
      gap: 10px;
    }

    .left_container {
      margin: 0px !important;
    }

    .popup_container {
      #chartPopup {
        width: 1200px !important;
      }
    }

    .flyash_card_table_content {
      flex-direction: column !important;
    }
  }

  .spinner_content {
    text-align: center;
  }

  // Image component scss
  .image_text {
    font-size: 14px;
    font-weight: $font_weight_medium_2;
  }

  .image_text1 {
    font-size: 13px;
    font-weight: $font_weight_medium_2;
  }

  .image_text2 {
    font-size: 11px;
    font-weight: $font_weight_medium_2;
  }

  .boiler_image {
    width: 100%;
    height: 330px;
  }

  .simulator_image {
    width: 100%;
    height: 450px;
  }

  .uploadLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 5px;
    border: 1.083px solid #e0e0e0;
    background: #fff;
    width: 100%;
    height: min-content;
    font-size: 6px;
    font-weight: 500;
    gap: 10px;
    cursor: pointer;
  }

  .upload_btn {
    display: none;
  }

  .upload_file {
    margin-top: 15px;
    /* margin-bottom: 1rem; */
    font-size: 12px;
  }

  // .selected_file {
  //   font-size: 10px;
  //   font-family: $font_family_montserrat;
  //   margin: 0;
  //   text-align: end;
  //   overflow: scroll;
  //   width: 100px;
  //   padding-top: 15px;
  // }
  .selected_file {
    font-size: 10px;
    font-family: $font_family_montserrat;
    margin: 0;
    text-align: end;
    overflow: scroll;
    width: 100%;
    // max-width: 100px;
    white-space: pre-wrap;
    padding-top: 15px;
  }

  .dropdown_menu {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    position: absolute;
    z-index: 10;
  }

  .side_drop_heading {
    font-size: 16px;
    font-weight: $font_weight_medium_2;
  }

  .view_button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-info-100;
    color: $color-danger-200;
    border-radius: 5px;
    border: $border_width $color-secondary-1000;
    width: 87px;
    height: 26px;

    font-size: 12px;
    cursor: pointer;
  }

  .no_data {
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-weight: 500;
    color: $color-secondary-1200;
    align-items: center;
    height: 360px;
  }

  .error {
    font-size: 12px;
    color: #EE1B22;
  }
  .success {
    font-size: 12;
    color: #00b050;
  }

  .loader {
    --size-loader: 50px;
    --size-orbe: 10px;
    width: var(--size-loader);
    height: var(--size-loader);
    position: relative;
    transform: rotate(45deg);
  }

  .orbe {
    position: absolute;
    width: 100%;
    height: 100%;
    --delay: calc(var(--index) * 0.1s);
    animation: orbit7456 ease-in-out 1.5s var(--delay) infinite;
    opacity: calc(1 - calc(0.2 * var(--index)));
  }

  .orbe::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: var(--size-orbe);
    height: var(--size-orbe);
    background-color: #3ae374;
    box-shadow: 0px 0px 20px 2px #3ae374;
    border-radius: 50%;
  }

  @keyframes orbit7456 {
    0% {
    }

    80% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
